<template>
    <base-content>
        <template #button>
            <a-button @click="newestSearch" type="primary">最近更新</a-button>
            <a-button @click="auditSearch" type="primary">等待审核</a-button>
        </template>
        <a-input-search v-model:value="serial" placeholder="出库流水号" enter-button="查询" style="width:36%" @search="serialSearch" />
        <div style="width:64%;padding-left:3vw;display:inline-flex;">
            <a-range-picker v-model:value="date_range" valueFormat="YYYY-MM-DD" style="flex-grow:1;border-top-right-radius:unset;border-bottom-right-radius:unset;border-right:unset;" />
            <a-button type="primary" style="border-top-left-radius:unset;border-bottom-left-radius:unset;margin-left:-1px" @click="dateSearch">查询</a-button>
        </div>
        <a-table style="margin-top:12px" bordered :columns="columns" :dataSource="datas" rowKey="serial" :size="auto_size" :pagination="false">
            <template #operation="scoped">
                <a-button :size="auto_size" @click="$refs.OutStorage.show(scoped.record.serial)">详情</a-button>
            </template>
        </a-table>
        <OutStorage ref="OutStorage" />
    </base-content>
</template>

<script>
import OutStorage from '@/components/modal/OutStorage';

export default {
    components: { OutStorage },
    data() {
        return {
            serial: '',
            date_range: ['', ''],
            columns: [
                { title: '出库流水号', dataIndex: 'serial' },
                { title: '当前节点', dataIndex: 'flow_node' },
                { title: '物料种类', dataIndex: 'material_count', align: 'center' },
                { title: '最近更新时间', dataIndex: 'newest_time', align: 'center' },
                { title: '最新经办人', dataIndex: 'newest_user', align: 'center' },
                { title: '操作', slots: { customRender: 'operation' }, align: 'center' },
            ],
            datas: [],
            auto_size: 'default',
        };
    },
    methods: {
        serialSearch() {
            this.serial = this.serial.replace(' ', '');
            if (!this.serial) {
                this.$message.error('请输入序列号');
                return;
            }
            this.$api('searchOutStorageSerial', { serial: this.serial }).then((res) => {
                this.$message.success('已更新为按出库流水号搜索的结果');
                this.parseData(res.out_storage_list);
            });
        },
        dateSearch() {
            if (!this.date_range[0] && !this.date_range[1]) {
                this.$message.error('请选择日期范围');
                return;
            }
            this.$api('searchOutStorageDateRange', { start_date: this.date_range[0], end_date: this.date_range[1] }).then((res) => {
                this.$message.success('已更新为按照日期搜索的结果');
                this.parseData(res.out_storage_list);
            });
        },
        newestSearch() {
            this.$api('searchOutStorageNewest').then((res) => {
                this.$message.success('已更新为最近更新的数据');
                this.parseData(res.out_storage_list);
            });
        },
        auditSearch() {
            this.$api('searchOutStoragePendingAudit').then((res) => {
                this.$message.success('已更新为等待审核的数据');
                this.parseData(res.out_storage_list);
            });
        },
        parseData(out_storage_record_list) {
            this.datas = [];
            for (let i = 0; i < out_storage_record_list.length; i++) {
                let flow_logs = out_storage_record_list[i].flow_logs;
                let user_id = flow_logs[flow_logs.length - 1].user_id;
                this.$api('getUserInfo', { user_id }).then((res) => {
                    out_storage_record_list[i].newest_user = res.name;
                    out_storage_record_list[i].newest_time = flow_logs[flow_logs.length - 1].time;
                    out_storage_record_list[i].material_count = `共${out_storage_record_list[i].material_list.length}种`;
                    this.datas.push(out_storage_record_list[i]);
                    if (this.datas.length >= 0) this.auto_size = 'default';
                    if (this.datas.length >= 15) this.auto_size = 'middle';
                    if (this.datas.length >= 40) this.auto_size = 'small';
                });
            }
        },
    },
};
</script>

<style></style>
